:root {
  --sidebarFullWidth: 15rem;
  --headerHeight: 7.5rem;
  --marginTopContent: -1.5rem;
  --mobileContainerPadding: 1.3rem;
  --mobileNavigationHeight: 3rem;
}

html {
  height: 100%;
}

body {
  background: #f2f2f2;
  color: #333;
  /* font-family: "Roboto", sans-serif; */
  font-family: 'Montserrat', sans-serif;
  height: 100%;
}
a {
  color: #333;
  text-decoration: none;
}
